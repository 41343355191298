.aboutus-section {
  color: #fff;
  padding: 10px 0 0 10px;
  /* padding: 0 0; */
}

.container {
  /* background-color: yellow; */
}

.aboutus-row {
  align-items: center;
}

.row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
}

/* .col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  background-color: yellow;
} */

.col1 {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 100%;
  text-align: justify;

  /* background-color: aqua; */
}

.col2 {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 30%;
  flex-basis: 50%;

  /* background-color: aqua; */
}
.aboutus-text-wrapper {
  /* max-width: 540px; */
  /* max-width: 600px; */
  padding-top: 0;
  padding-bottom: 10px;

  /* background-color: green; */
}

.top-line {
  color: #ed1c22;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #f7f8fa;
}

.dark {
  color: #1c2237;
}

.darkBg {
  background-color: #1c2237;
}

.aboutus-subtitle {
  /* max-width: 440px; */
  /* max-width: 580px; */
  /* margin-bottom: 35px; */
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 24px;
  /* justify-content: center; */
  align-items: center;
  /* background-color: red; */
}

.aboutus-img-wrapper {
  max-width: 555px;
  /* max-width: 495px; */
}

.aboutus-img {
  max-width: 95%;
  /* max-width: 60%; */
  margin-top: 0;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }

  .col1 {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 100%;
    flex-basis: 100%;
    text-align: justify;
  
    /* background-color: aqua; */
  }
  
  .col2 {
    /* display: none; */
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 100%;
    flex-basis: 100%;    
  }  
}

@media screen and (max-width: 768px) {
  .aboutus-text-wrapper {
    padding-bottom: 65px;
  }

  /* .col {
    max-width: 100%;
    flex-basis: 100%;
  } */
}
