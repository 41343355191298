.clients-section {
    color: #fff;
    padding: 20px 0;
  }
  
  .clients-row {
    align-items: center;
  }
  
  .row {
    display: flex;
    margin-right: -15px;
    margin-bottom: -15px;
    margin-left: -15px;
    flex-wrap: wrap;
    align-content: stretch;
  }
  
  .clients-img-col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
  }

  .text-col {
    margin-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 100%;
    flex-basis: 100%;
    /* background-color: orange; */
  }
  
  .clients-text-wrapper {
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 20px;
  }
  
  .client-top-line {
    color: #ed1c22;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    /* letter-spacing: 1.4px; */
    text-transform: uppercase;
    margin-bottom: 16px;

    /* background-color: green; */
  }
  
  .heading {
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;
  }
  
  .dark {
    color: #1c2237;
  }
  
  .darkBg {
    background-color: #1c2237;
  }
  
  .clients-subtitle {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
  }
  
  .clients-img-wrapper {
    max-width: 555px;
    /* background-color: green;     */
  }
  
  .clients-img {
    max-width: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0px;
    padding-right: 0;
    padding-bottom: 29px;

    /* background-color: orange; */
  }
  
  img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  
  @media screen and (max-width: 991px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .clients-text-wrapper {
      padding-bottom: 65px;
    }

    .clients-img-col {
        max-width: 100%;
        flex-basis: 100%;
    }    

    .client-top-line {
        margin-bottom: 0px;
    }      
  }
  